const CACHE_NAME = 'my-cache-v1';
const URLS_TO_CACHE = [
    '/',
    '/index.html',
    '/styles.css',
    '/app.js',
    // Add other URLs to cache
];

// Install event: Precache resources
self.addEventListener('install', event => {
    event.waitUntil(
        caches.open(CACHE_NAME).then(cache => {
            console.log('Pre-caching offline resources');
            return cache.addAll(URLS_TO_CACHE);
        })
    );
});

// Activate event: Remove old caches
self.addEventListener('activate', event => {
    const cacheWhitelist = [CACHE_NAME];
    event.waitUntil(
        caches.keys().then(cacheNames => {
            return Promise.all(
                cacheNames.map(cacheName => {
                    if (cacheWhitelist.indexOf(cacheName) === -1) {
                        // Delete old cache
                        return caches.delete(cacheName);
                    }
                })
            );
        }).then(() => {
            // Claim clients to activate the new service worker immediately
            return self.clients.claim();
        })
    );
});

// Fetch event: Serve cached resources or fetch from network
self.addEventListener('fetch', event => {
    event.respondWith(
        caches.match(event.request).then(response => {
            // Return cached resource if found, otherwise fetch from network
            return response || fetch(event.request);
        })
    );
});

// Optional: Listen for messages from the client to clear cache
self.addEventListener('message', event => {
    if (event.data.action === 'CLEAR_CACHE') {
        caches.keys().then(cacheNames => {
            return Promise.all(
                cacheNames.map(cacheName => caches.delete(cacheName))
            ).then(() => {
                console.log('All caches cleared.');
            });
        });
    }
});
