// service.js

import axios from 'axios';

const baseURL = process.env.VUE_APP_SERVER_URL; // Your backend base URL
// const baseURL = 'http://localhost:8081'; // Your backend base URL

// Function to fetch employees from the backend
const get = (arr) => {
  var path = "";
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    if (i == 0) path += "?id="+element+",";
    // else if (i==arr.length-1) path += element;
    else path += element+",";
  }

  // Remove the last character (comma)
  if (path.endsWith(',')) {
    path = path.slice(0, -1);
  }

  // for (let i = 0; i < arr.length; i++) {
  //   const element = arr[i];
  //   if (i == 0) path += "?";
  //   else path += "&";
  //   path += "id=" + element;
  // }

  path = "/path" + path;

  const url = `${baseURL}${path}`;
  
  return axios.get(url)
    .then(response => response.data)
    .catch(error => {
      // console.error('Error fetching employees:', error);
      throw error; // Re-throw the error to be handled by the caller
    });
};


// Function to fetch employees from the backend
const post = (arr) => {
  var path = "";
  // for (let i = 0; i < arr.length; i++) {
  //   const element = arr[i];
  //   if (i == 0) path += "?";
  //   else path += "&";
  //   path += "id=" + element;
  // }

  path = "/senData";

  const url = `${baseURL}${path}`;

  // Return the promise returned by axios.post
  return axios.post(url, { arr })
    .then(response => {
      // console.log('Response:', response.data);
      return response.data; // Return the response data
    })
    .catch(error => {
      // console.error('Error pushing students:', error);
      throw error; // Re-throw the error to be handled by the caller
    });
};

// Define other functions here...

// Export an object with all the functions
export const api = {
  get,  
  post
  // Add other functions here...
};