class Utilisateur {
    constructor(
        id = "",
        nom = "",   
        postnom = "",
        prenom = "",
        sexe = "",
        email = "",
        telephone = "",
        nationalite = "",
        imageProfile = "",
        dateDeNaissance = "",
        domicile = "",
        territoire = "",
        ville = "",
        province = ""
    ) {
        const defineProperty = (propertyName, value) => {
            Object.defineProperty(this, propertyName, {
                enumerable: true,
                value: value,
                writable: false
            });
        };

        defineProperty('id', id);
        defineProperty('nom', nom);
        defineProperty('postnom', postnom);
        defineProperty('prenom', prenom);
        defineProperty('sexe', sexe);
        defineProperty('email', email);
        defineProperty('telephone', telephone);
        defineProperty('nationalite', nationalite);
        defineProperty('imageProfile', imageProfile);
        defineProperty('dateDeNaissance', dateDeNaissance);
        defineProperty('domicile', domicile);
        defineProperty('territoire', territoire);
        defineProperty('ville', ville);
        defineProperty('province', province);
    }

    // Getters
    getId() {
        return this.id;
    }

    getNom() {
        return this.nom;
    }

    getPostnom() {
        return this.postnom;
    }

    getFullname() {
        return this.nom + " " + this.postnom + " " + this.prenom;
    }

    getPrenom() {
        return this.prenom;
    }

    getSexe() {
        return this.sexe;
    }

    getEmail() {
        return this.email;
    }

    getTelephone() {
        return this.telephone;
    }

    getNationalite() {
        return this.nationalite;
    }

    getImageProfile() {
        return this.imageProfile;
    }

    getDateDeNaissance() {
        return this.dateDeNaissance;
    }

    getDomicile() {
        return this.domicile;
    }

    getTerritoire() {
        return this.territoire;
    }

    getVille() {
        return this.ville;
    }

    getProvince() {
        return this.province;
    }
}

export { Utilisateur };
