// plugins/vuetify.js

import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import "vuetify/dist/vuetify.min.css"; // Add this line
import '@mdi/font/css/materialdesignicons.css'

import colors from 'vuetify/util/colors';

// Function to get a cookie value by name
function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

var darkModeEnabled=getCookie("darkModeEnabled");

if(darkModeEnabled) darkModeEnabled = true;
else darkModeEnabled = false;

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: darkModeEnabled, 
        colors: {
          primary: colors.blue.darken1, // #E53935
          secondary: colors.red.lighten4, // #FFCDD2
          tertiary: colors.grey.lighten4, // #FFCDD2
          grey: colors.grey.darken4, // #FFCDD2
          // program: colors.purple.lighten1 ,
          // program: '#e0093f',
          program: '#055C9D',
          // program: colors.deepPurple.darken2,
          silver: colors.grey.lighten2,
        }
      },
      
    },
  },
});

export default vuetify;

// plugins/vuetify.js

// import { createVuetify } from 'vuetify';
// import * as components from 'vuetify/components';
// import * as directives from 'vuetify/directives';
// import "vuetify/dist/vuetify.min.css"; // Add this line
// import '@mdi/font/css/materialdesignicons.css'

// import colors from 'vuetify/util/colors';

// export default function createCustomVuetify() {
//   var darkModeEnabled = localStorage.getItem('darkModeEnabled');
//   alert("darkModeEnabled = " +darkModeEnabled);
//   return createVuetify({
//     components,
//     directives,
//     theme: {
//       themes: {
//         light: {
//           dark: darkModeEnabled,
//           colors: {
//             primary: colors.blue.darken1, // #E53935
//             secondary: colors.red.lighten4, // #FFCDD2
//             tertiary: colors.grey.lighten4, // #FFCDD2
//             grey: colors.grey.darken4, // #FFCDD2
//             program: colors.purple.lighten1 
//           }
//         },
//       },
//     },
//   }); 
// }
