import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// My COde
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Initialize AOS
AOS.init();

import vuetify from './plugins/vuetify'; // Import Vuetify
import '@fortawesome/fontawesome-free/css/all.css'
// End my code

// import 'leaflet/dist/leaflet.css';


const app = createApp(App);
app.use(store).use(vuetify).use(router).mount("#app");
